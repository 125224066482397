<template>
  <div class="w-full min-h-screen bg-gray-50 flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
    <div class="w-full sm:max-w-md p-5 mx-auto">
      <h2 class="mb-12 text-center text-5xl font-extrabold">Regjistrohu.</h2>
      <form @submit.prevent="register" @keydown="form.onKeydown($event)">
        <div class="bg-green-200 border-t-4 border-green-500 rounded-b text-teal-900 px-4 py-3 mb-3 shadow-md" role="alert" v-if="form.successful">
          <div class="flex">
            <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
            <div>
              <p class="font-bold">Llogaria juaj u krijua me sukses</p>
              <p class="text-sm"> <router-link :to="{name:'Login'}">Logohu në llogarinë tuaj</router-link></p>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label class="block mb-1" for="first_name">Emri</label>
          <input id="first_name" type="text" v-model="form.first_name" class="form-input" :class="{ error: form.errors.has('first_name') }" />
          <div v-if="form.errors.has('first_name')" class="form-error" v-html="form.errors.get('first_name')"></div>
        </div>
        <div class="mb-4">
          <label class="block mb-1" for="last_name">Mbiemri</label>
          <input id="last_name" type="text" v-model="form.last_name" class="form-input" :class="{ error: form.errors.has('last_name') }" />
          <div v-if="form.errors.has('last_name')" class="form-error" v-html="form.errors.get('last_name')"></div>
        </div>
        <div class="mb-4">
          <label class="block mb-1" for="name">Emri i kompanisë</label>
          <input id="name" type="text" v-model="form.name" class="form-input" :class="{ error: form.errors.has('name') }" />
          <div v-if="form.errors.has('name')" class="form-error" v-html="form.errors.get('name')"></div>
        </div>
        <div class="mb-4">
          <label class="block mb-1" for="address">Adresa</label>
          <input id="address" type="text" v-model="form.address" class="form-input" :class="{ error: form.errors.has('address') }" />
          <div v-if="form.errors.has('address')" class="form-error" v-html="form.errors.get('address')"></div>
        </div>
        <div class="mb-4">
          <label class="block mb-1" for="phone">Telefoni</label>
          <input id="phone" type="text" v-model="form.phone" class="form-input" :class="{ error: form.errors.has('phone') }" />
          <div v-if="form.errors.has('phone')" class="form-error" v-html="form.errors.get('phone')"></div>
        </div>
        <div class="mb-4">
          <label class="block mb-1" for="email">Email</label>
          <input id="email" type="text" v-model="form.email" class="form-input" :class="{ error: form.errors.has('email') }" />
          <div v-if="form.errors.has('email')" class="form-error" v-html="form.errors.get('email')"></div>
        </div>
        <div class="mb-4">
          <label class="block mb-1" for="password">Fjalëkalimi</label>
          <input id="password" type="password" v-model="form.password" class="form-input" :class="{ error: form.errors.has('password') }" />
          <div v-if="form.errors.has('password')" class="form-error" v-html="form.errors.get('password')"></div>
        </div>
        <div class="mb-4">
          <label class="block mb-1" for="password_confirmation">Konfirmo fjalëkalimin</label>
          <input id="password_confirmation" type="password" v-model="form.password_confirmation" class="form-input" :class="{ error: form.errors.has('password_confirmation') }" />
          <div v-if="form.errors.has('password_confirmation')" class="form-error" v-html="form.errors.get('password_confirmation')"></div>
        </div>
        <div class="mt-6">
          <button :disabled="form.busy" class="w-full inline-flex items-center justify-center px-4 py-2 bg-indigo-900 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-indigo-800 active:bg-indigo-800 focus:outline-none focus:border-indigo-800 focus:ring focus:ring-indigo-600 disabled:opacity-25 transition">Regjistrohu</button>
        </div>
        <div class="mt-6 text-center">
          <router-link :to="{name:'Login'}" class="underline">Kthehu te login</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from 'vform'
import axios from 'axios';
import {API_URL} from '@/.env';
export default {
  data() {
    return {
      form: new Form({
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        name: null,
        phone: null,
        address: null,
      })
    };
  },
  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
  },
  methods: {
    async register () {
      // Submit the form.
      const instance = axios.create({
        baseURL: API_URL
      })

      Form.axios = instance

      const { data } = await this.form.post('/api/register')

      this.form.reset()
    }
  }
}
</script>

<style></style>
